export default defineNuxtRouteMiddleware((to, from) => {

  const store = useStore();

  if (['/mints', '/wallets', '/portfolio', '/billing'].includes(to.path)) {
    if (!store.isLoggedIn) return navigateTo('/');
    if (store.customsRequired) return navigateTo('/customs');
  }

  // Stay on the same path.
  if (to.path === '/' && store.isLoggedIn && !store.customsRequired) return navigateTo(from.path);
  if (to.path === '/' && store.customsRequired) return navigateTo('/customs');
  if (to.path === '/customs') {
    if (store.customsRequired) return;
    return navigateTo('/mints');
  }

  return;
});
